* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
body{
  overflow-x: hidden;
}

.home,
.mission,
.vision,
.sign-up,
.contact {
  display: flex;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.mission, .vision{
  overflow: hidden;
  color: white;
  text-shadow:1px 1px 3px white;
}
.mission .dark-layer, .vision .dark-layer, .contact .dark-layer{
  background-color: hsla(0, 0%, 0%, 0.4);
  backdrop-filter: blur(1px);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width:100dvw;
  height: 100dvh;
  z-index: -1;
}

.mission img, .vision img, .contact img:not(.loader img){
  position: absolute;
  z-index:-2;
  width:100dvw;
  height:100dvh;
  left : 0;
  top: 0;
  object-fit: cover;
}

.mission p, .vision p{
  width:clamp(360px, 50%, 100%);
  font-size: clamp(1rem, 2vw, 4rem);
  text-align:justify;
  text-align-last:center;
}

.sign-up {
  background-image: url('./../public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
