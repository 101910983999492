/* src/components/Gallery.css */

.gallery {
    display: grid;
   /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));*/
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    padding: 10px 100px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ease, z-index 0.5s ease;
    transform-origin: center center;
  }
  
  .gallery-item img, .gallery-item video{
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    display: block;
  }

  @media screen and (max-width: 960px){
    .gallery{
      grid-template-columns: auto;
      padding: 10px;
    }
  }