.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.contact-form h1{
    color: white;
}

form.form-control{
    background-color: hsla(0, 0%, 50%, 0.3)!important;
    backdrop-filter: blur(5px);
    border:none;
    outline: none;
    box-shadow: 1px 2px 4px 6px hsla(0, 0%, 50%, 0.3);
    width: clamp(300px, 50%, 90dvw);
    margin: 0 auto;
}

.contact-form button{
    width: clamp(40%, 200px, 100%);
}

.contact-form textarea{
    min-height: 100px!important;
    position: relative;
}
.contact .loader{
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index:999999999;
}
.contact .loader img{
    width: 200px;
    height: 200px;
    z-index:99999999;
}