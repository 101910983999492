.about{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.about img{
    height: 400px;
    object-fit: cover;
}

.about p{
    width: 50%;
}