.events{
    padding-bottom: 1em;
}
.events p{
    font-size: clamp(1rem, 2vw, 4rem);
}

.no-events{
    color:red;
    margin: 0 auto;
}

.event{
    display: grid;
    grid-template-columns: 500px 500px;
    grid-gap: 10px;
}

.event h5{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 960px){
    .event{
        grid-template-columns: auto;
    }
}