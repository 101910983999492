.newroz{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newroz > section{
    position: relative;
    width: 600px;
}
.newroz img{
    width: 100%;
}